<style scope>
  body{
    min-height: 100%;
  }
  .tab-list{
    border-bottom: 1px solid #eeeeee;
  }
  .tab-list ul{
    display: -webkit-box;
  }
  .tab-list ul li{
    -webkit-box-flex: 1;
    text-align: center;
    font-size: 0.875rem;
    line-height: 40px;
    height: 40px;
  }
  .tab-list ul li div{
    display: inline-block;
    /*width: 60px;*/
  }
  .tab-list ul li span{
    position: relative;
    top: -1px;
    border-bottom: 2px solid transparent;
  }
  .tab-list ul li.on div span{
    display: block;
    color: #547CFF;
    border-bottom: 2px solid #547CFF;
  }
  .tab-con{
    min-height: calc(100% - 41px);
    overflow: hidden;
  }
  .near-used{
    padding: 15px;
  }
  .type-con{
    overflow: hidden;
    height: 100%;
  }
  .type-con .second-list{
    float: left;
    background: rgba(255,255,255,0.5);
    height: 100%;
  }
  .type-con .second-list li{
    height: 35px;
    line-height: 35px;
    width: 80px;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    color: #777777;
  }
  .type-con .second-list li.on{
    background:#547CFF  ;
    color: #fff;
    border-bottom: 1px solid #547CFF  ;
  }
  .type-con .bg-list{
    margin-left: 80px;
    padding: 15px 10px;
  }
  .bg-list-con{
    overflow: hidden;
  }
  .bg-list-con li{
    float:left;
    width: 22%;
    margin-right: 4%;
    margin-bottom: 10px;
    text-align: center;
    color: #999;
  }
  .bg-list .bg-list-con li:nth-of-type(4n){
    margin-right: 0;
  }
  .near-used .bg-list-con li{
    width: 16%;
    margin-right: 5%;
  }
  .near-used .bg-list-con li:nth-of-type(5n){
    margin-right: 0;
  }
  .bg-list-con li a{
    display: block;
    border-radius: 0px;
    overflow: hidden;
    position: relative;
    /*background: #4e43e1;*/
    color: #fff;
  }
  .bg-list-con li img{
    width: 100%;
    display: block;
  }
  .bg-list-con li.on a::after{
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: url("../../assets/image/selected01.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bg-list-con li p{
    margin-top: 3px;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bg-list-con li.on p{
    color: #4e43e1;
  }
  .booknull{
    margin-top: 180px;
  }
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: -1;
      width: 100%;height: 100%;
    }
    .pagebgimg>img{
      width: 100%;height: 100%;
    }
</style>
<template>
  <div class="chapter-bg">
    <header-title titleCon="背景库" titleBtnStr="完成" @titleLeftBtn="titleFnReturn" @titleFnBtn="titleFnListener"></header-title>
    <div class="chapter-bg-list" id="chapterBgCon">
      <div class="tab-list">
        <ul>
          <li :class="typeActive.firstTypeIndex === 0 ? 'on': ''">
            <div><span @click="firstNearFn">最近使用</span></div>
          </li>
          <li v-for="(item, index) in ugcBackpicTypes" :key="index" :class="typeActive.firstTypeIndex === (index + 1) ? 'on': ''">
            <div><span @click="firstTypeFn(item.backpicType, index+1)">{{item.backpicTypeName}}</span></div>
          </li>
        </ul>
      </div>
      <div class="tab-con">
        <!--最近使用-->
        <div class="near-used" v-if="typeActive.firstTypeIndex === 0">
          <ul class="bg-list-con" v-if="nearList.length>0">
            <li v-for="(item, index) in nearList" :class="item.selected ? 'on': ''" :key="index">
              <a  @click="changeFace(index)">
                <span><img :src="item.backpicPath + '?x-oss-process=image/resize,m_fixed,h_480,w_300'" :title="item.backpicTitle"></span>
              </a>
              <p>{{item.backpicTitle}}</p>
            </li>
          </ul>
          <div v-else>
            <div class="booknull">
              <div class="booknullimg">
                <img src="../../assets/image/book_null.png">
                <p>暂无使用历史~</p>
              </div>
            </div>
          </div>
        </div>
        <!--二级分类-->
        <div class="type-con" v-else>
          <div class="second-list">
            <ul>
              <li v-for="(item, index) in backpicSubType" :key="index" :data-id="item.id" @click="subTypeShow(item.id, index)" :class="typeActive.subTypeIndex === index ? 'on': ''">
                {{item.subTypeName}}
              </li>
            </ul>
          </div>
          <div class="bg-list">
            <ul class="bg-list-con" v-if="ugcBackPicList.length>0">
              <li v-for="(item, index) in ugcBackPicList" :class="item.selected ? 'on': ''" :key="index">
                <a @click="changeFace(index)">
                  <span><img :src="item.backpicPath + '?x-oss-process=image/resize,m_fixed,h_480,w_300'" :title="item.backpicTitle"></span>
                </a>
                <p>{{item.backpicTitle}}</p>
              </li>
            </ul>
            <div v-else>
              <div class="booknull">
                <div class="booknullimg">
                  <img src="../../assets/image/book_null.png">
                  <p>暂无该分类~</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
export default {
  components: {
    headerTitle
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      // isSelect: false,
      sltBgId: -1,
      sltCptBgActive: {
        firstTypeIndex: 0, // 一级分类选中
        firstTypeId: 11, // 一级分类选中的id
        subTypeIndex: 0, // 二级分类选中
        subTypeId: 0,
        listActive: -1 // 背景选中
      },
      typeActive: {
        firstTypeIndex: 0, // 一级分类选中
        firstTypeId: 11, // 一级分类选中的id
        subTypeIndex: 0, // 二级分类选中
        subTypeId: 0, // 二级分类选中id
        listActive: -1
      },
      ugcBackPicList: [], // 背景列表
      ugcBackpicTypes: [], // 一级分类所有列表
      backpicSubType: [], // 选中的一级分类下的二级分类列表
      nearList: [] // 最近使用列表
    }
  },
  computed: {
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    // 分类接口
    this.axios({
      method: 'get',
      url: '/v1/backPicList',
      params: {
        backpicType: '',
        backpicSubType: ''
      }
    }).then((res) => {
      if (res.data.head.flag === 0) {
        // console.log(res.data.content)
        this.ugcBackpicTypes = res.data.content.ugcBackpicTypes || []
      } else {
        this.$toast(res.data.head.desc)
      }
    })
    // 最近使用
    this.axios({
      method: 'get',
      url: '/v1/recentBackPics',
      params: {
        // authorId: sessionStorage.getItem('authorId')
      }
    }).then((res) => {
      if (res.data.head.flag === 0) {
        this.nearList = res.data.content.ugcBackPicList || []
      } else {
        this.$toast(res.data.head.desc)
      }
    })
  },
  mounted () {
    document.getElementById('chapterBgCon').style.height = (window.screen.height - 46) + 'px'
    // 默认选中
    if ((localStorage.getItem('sltCptBgActive') !== 'null') && (localStorage.getItem('sltCptBgActive') !== null) && (localStorage.getItem('sltCptBgActive') !== undefined) && (localStorage.getItem('sltCptBgActive') !== 'undefined') && (localStorage.getItem('sltCptBgActive') !== '')) {
      // console.log(11)
      this.typeActive = JSON.parse(localStorage.getItem('sltCptBgActive'))
      // console.log(this.typeActive)
      this.sltBgId = parseInt(localStorage.getItem('sltBgId'))
      if (this.typeActive.firstTypeIndex !== 0) {
        this.axios({
          method: 'get',
          url: '/v1/backPicList',
          params: {
            backpicType: this.typeActive.firstTypeId,
            backpicSubType: this.typeActive.subTypeId
          }
        }).then((res) => {
          if (res.data.head.flag === 0) {
            this.backpicSubType = res.data.content.ugcBackpicTypes[this.typeActive.firstTypeIndex - 1].backpicSubType || []
            this.ugcBackPicList = res.data.content.ugcBackPicList || []
            this.ugcBackPicList[this.typeActive.listActive].selected = true
          } else {
            this.$toast(res.data.head.desc)
          }
        })
      } else {
        this.axios({
          method: 'get',
          url: '/v1/recentBackPics',
          params: {
            // authorId: sessionStorage.getItem('authorId')
          }
        }).then((res) => {
          if (res.data.head.flag === 0) {
            this.nearList = res.data.content.ugcBackPicList || []
            this.nearList.forEach(function (event) {
              if (event.id === parseInt(localStorage.getItem('sltBgId'))) { // 最近使用选中
                event.selected = true
              }
            })
          } else {
            this.$toast(res.data.head.desc)
          }
        })
      }
    }
  },
  methods: {
    // 选择背景返回
    titleFnReturn () {
      this.$router.go(-1)
    },
    // 选择背景完成
    titleFnListener () {
      this.sltCptBgActive = this.typeActive
      if (this.nearList.length > 0 || this.ugcBackPicList.length > 0) { // 列表有数据
        if (this.sltCptBgActive.listActive !== -1) { // 选中背景
          this.$store.state.sltCptBgActive = this.sltCptBgActive
          localStorage.setItem('sltCptBgActive', JSON.stringify(this.sltCptBgActive))
          localStorage.setItem('sltBgId', this.sltBgId)
          if (this.sltCptBgActive.firstTypeIndex === 0) { // 最近使用列表
            this.$store.state.chapterInfo.chapterBg = this.nearList[this.sltCptBgActive.listActive]
          } else { // 二级分类列表
            this.$store.state.chapterInfo.chapterBg = this.ugcBackPicList[this.sltCptBgActive.listActive]
          }
        } else { // 取消背景
          localStorage.setItem('sltCptBgActive', '')
          localStorage.setItem('sltBgId', '')
          this.$store.state.chapterInfo.chapterBg = {
            addTime: '',
            backpicPath: '',
            backpicSubType: -1,
            backpicTitle: '',
            backpicType: -1,
            id: '',
            rackStatus: -1,
            selected: false,
            updTime: null
          }
          this.$store.state.sltCptBgActive = {
            firstTypeIndex: '',
            firstTypeId: '',
            subTypeIndex: '',
            subTypeId: '',
            listActive: ''
          }
        }
        this.$router.go(-1)
        // this.$router.push({ path: '/chapterAdd', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type } })
      } else {
        this.$toast('没有该分类')
      }
    },
    // 最近使用切换
    firstNearFn () {
      this.typeActive.firstTypeIndex = 0
      this.typeActive.firstTypeId = 11
      var that = this
      this.nearList.forEach(function (event) {
        if (event.id === that.sltBgId) { // 最近使用选中
          event.selected = true
        }
      })
    },
    // 一级分类切换
    firstTypeFn (firstTypeId, firstTypeIndex) {
      this.typeActive.firstTypeIndex = firstTypeIndex
      this.typeActive.firstTypeId = firstTypeId
      this.typeActive.subTypeIndex = 0
      // console.log(this.typeActive)
      // console.log(this.typeActive.firstTypeIndex - 1)
      // console.log(this.ugcBackpicTypes[this.typeActive.firstTypeIndex - 1].backpicSubType[0])
      this.typeActive.subTypeId = this.ugcBackpicTypes[firstTypeIndex - 1].backpicSubType[this.typeActive.subTypeIndex].id
      var that = this
      this.axios({
        method: 'get',
        url: '/v1/backPicList',
        params: {
          backpicType: firstTypeId,
          backpicSubType: this.ugcBackpicTypes[firstTypeIndex - 1].backpicSubType[0].id
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          that.backpicSubType = res.data.content.ugcBackpicTypes[firstTypeIndex - 1].backpicSubType
          that.ugcBackPicList = res.data.content.ugcBackPicList
          that.ugcBackPicList.forEach(function (event) {
            if (event.id === that.sltBgId) { // 最近使用选中
              event.selected = true
            }
          })
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 二级分类切换
    subTypeShow (subTypeId, subTypeIndex) {
      this.typeActive.subTypeIndex = subTypeIndex
      this.typeActive.subTypeId = subTypeId
      var that = this
      this.axios({
        method: 'get',
        url: '/v1/backPicList',
        params: {
          backpicType: this.typeActive.firstTypeId,
          backpicSubType: subTypeId
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          that.ugcBackPicList = res.data.content.ugcBackPicList
          that.ugcBackPicList.forEach(function (event) {
            if (event.id === that.sltBgId) { // 最近使用选中
              event.selected = true
            }
          })
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 背景选择
    changeFace (index) {
      if (this.typeActive.firstTypeIndex === 0) {
        if (this.nearList[index].selected) {
          this.nearList[index].selected = false
          this.typeActive.listActive = -1
        } else {
          this.nearList.forEach(function (e) {
            e.selected = false
          })
          this.typeActive.listActive = index
          this.nearList[index].selected = true
          this.sltBgId = this.nearList[index].id
        }
      } else {
        if (this.ugcBackPicList[index].selected) {
          this.ugcBackPicList[index].selected = false
          this.typeActive.listActive = -1
        } else {
          this.ugcBackPicList.forEach(function (e) {
            e.selected = false
          })
          this.typeActive.listActive = index
          this.ugcBackPicList[index].selected = true
          this.sltBgId = this.ugcBackPicList[index].id
        }
      }
    }
  }
}
</script>
